<template>
  <div>
    <div v-if="!formSubmitted">
      <img
        v-show="submitInProgress"
        class="py-16 mx-auto"
        width="231"
        height="231"
        src="/images/ripple-loading.gif"
        alt="loading"
      />
      <form
        v-if="!_isEmpty(formModel)"
        v-show="!submitInProgress"
        @submit.prevent="handleSubmitForm"
      >
        <h4 class="text-lg font-bold mb-4">
          Request callback
        </h4>
        <p class="mb-4">
          {{ verticals.requestCallbackText }}
        </p>

        <div
          v-for="(item, itemIndex) in formData"
          v-show="!(item.props.name==='user_email' && hideEmail && !_isEmpty(formModel['user_email']))"
          :key="itemIndex"
          class="mb-4 last:mb-6"
          :class="item.componentClass"
        >
          <component
            :is="item.component"
            v-bind="item.props"
            v-model="formModel[item.props.name]"
            class="lg:text-base input-form__text-xsm"
            :data-arr="dataArr[item.props.name]"
            :disabled="dataArr[item.props.name]?.length===0"
            :error-message="validation[item.props.name]?.errorMessage"
            :is-success="validation[item.props.name]?.isSuccess"
            @input="handleValidateFormItem(item.props.name, formModel[item.props.name])"
          />
        </div>

        <atoms-cta
          id="gsd-request_call_back_submit"
          type="submit"
          class="mb-4 py-3"
          size="small"
          :full="true"
          @click="$emit('submit-click', $event)"
        >
          REQUEST A CALLBACK
        </atoms-cta>

        <div class="text-center">
          <atoms-cta
            theme="tertiary"
            size="small"
            type="button"
            @click="cancel"
          >
            CANCEL
          </atoms-cta>
        </div>

        <!-- DISCLAIMER  -->
        <div
          v-if="verticalConfigObject.hasGsdDisclaimer"
          class="bg-white p-4 rounded-lg border-1 border-success text-left sm:mb-4 md:mb-8 mt-4 lg:mb-8 block"
        >
          <p class="m-0 text-sm leading-5 text-dark">
            By clicking '<span>REQUEST A CALLBACK</span>', I acknowledge that I
            have read and agree to the
            <AtomsLink
              class="text-success"
              target="_blank"
              to="/terms-of-use/"
            >
              Terms of Use
            </AtomsLink>, the
            <AtomsLink
              class="text-success"
              target="_blank"
              to="/privacy-policy/"
            >
              Privacy Policy
            </AtomsLink>
            and the
            <nuxt-link
              to="/collection-notice/"
              target="_blank"
            >
              <span class="text-success">Collection Notice</span>
            </nuxt-link>
            and I consent to you contacting me.
          </p>
        </div>
      </form>
    </div>

    <div
      v-else
      class="text-center"
    >
      <p class="text-lg font-bold">
        Success!
      </p>
      <IconsCheckedBox
        type="circle"
        class="w-14 h-14 mx-auto my-4"
      />

      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="verticals.requestCallbackTankyouMessage"></p>
      <h5 class="my-4 font-bold text-lg">
        Thank you!
      </h5>

      <atoms-cta
        id="gsd-request_call_back_success_form_close"
        theme="secondary"
        size="medium"
        class="mb-4"
        @click="closeEvent"
      >
        CLOSE
      </atoms-cta>
    </div>
  </div>
</template>

<script setup>
import _isEmpty from 'underscore/cjs/isEmpty.js'
import {
  emailValidator,
  validateFullname,
  phoneNumberValidator
} from '~/helpers/validator'
import { GUIDES_CONFIG } from '~/helpers/constants'
import { STATES } from '~/constants/states.constants'
import { REQUEST_CALLBACK_FORM, REQUEST_CALLBACK_FORM_NEW, REQUEST_CALLBACK_ERROR_MAP } from '~/constants/pages/schedule-callback'
import {
  handleScrollToError,
  GENERATE_FORM_MODEL,
  VALIDATE_FORM,
  VALIDATE_FORM_ITEM
} from '~/helpers/formfunnel.helpers.js'

defineOptions({
  name: 'MoleculesScheduleCallbackGeneric'
})

const { $toast, $tracking } = useNuxtApp()

const props = defineProps({
  vertical: {
    type: String,
    default: 'Health Insurance'
  },
  prefillData: {
    type: Object,
    default: () => ({})
  },
  additionalParams: {
    type: Object,
    default: () => ({})
  },
  hideEmail: {
    type: Boolean,
    default: false
  }
})

const newRequestCallbackVerticals = [VERTICAL.ENERGY, VERTICAL.LIFE_INSURANCE, VERTICAL.HOME_LOANS]
const brazeTrackVerticals = [VERTICAL.ENERGY, VERTICAL.LIFE_INSURANCE, VERTICAL.HOME_LOANS]

const utmsStore = useUtmsStore()
// ========================= Begin form handling ========================= //
const formModel = ref({})
const validation = ref({})

const formData = computed(() => props.vertical && newRequestCallbackVerticals.includes(props.vertical) ? REQUEST_CALLBACK_FORM_NEW : REQUEST_CALLBACK_FORM)
const errorMap = REQUEST_CALLBACK_ERROR_MAP
const submitInProgress = ref(false)
const formSubmitted = ref(false)

const dataArr = computed(() => {
  return {
    state: STATES
  }
})

// Initialize the form
function handleModels () {
  formModel.value = handleGenerateModel()

  // Prefill the default data available
  if (!_isEmpty(props.prefillData)) {
    for (const key of Object.keys(props.prefillData)) {
      formModel.value[key] = (props.prefillData[key] !== '' ? props.prefillData[key] : '')
    }
  }
  validation.value = handleGenerateModel('validation')
}

onMounted(() => {
  handleModels()
})

watch(() => props.prefillData, () => {
  handleModels()
})

// Generate the form modal from the formData
function handleGenerateModel (type = 'default') {
  return GENERATE_FORM_MODEL({
    type,
    form: formData.value
  })
}

// Add custom validation map
const validationMap = (key, value) =>
  getKey(key, {
    default: () => !_isEmpty(value),
    full_name: () => validateFullname(value),
    phone: () => phoneNumberValidator(value),
    user_email: () => emailValidator(value)
  })

// Handle form item specific validations
function handleValidateFormItem (key, value) {
  VALIDATE_FORM_ITEM({
    key,
    value,
    errorMap,
    validation,
    _function: validationMap(key, value)
  })
}

// Handle form validation
function handleValidateForm () {
  VALIDATE_FORM({
    form: formModel,
    validation,
    errorMap,
    externalValidation: ({ value, key }) => {
      const map = getKey(key, {
        full_name: () => handleValidateFormItem(key, value),
        user_email: () => handleValidateFormItem(key, value),
        phone: () => handleValidateFormItem(key, value)
      })

      if (map && map?.(key, value)) {
        map(key, value)
      }
    }
  })
}

const verticals = computed(() => VERTICALS[props.vertical])

const verticalConfigObject = computed(() => {
  const activeVertical = props.vertical
    ? props.vertical
    : VERTICALS.HEALTH_INSURANCE
  return GUIDES_CONFIG.config[activeVertical]
})

const callbackParams = computed(() => {
  const model = formModel.value

  return {
    vertical: props.vertical,
    fullname: model.full_name ?? '',
    phone: model.phone ?? '',
    email: model.user_email ?? '',
    state: model.state ?? ''
  }
})

const brazeCallbackTrack = async payload => {
  try {
    const phone = payload.phone?.replace('0', '+61')
    const params = {
      phone,
      email: payload?.email,
      event: 'request_callback',
      properties: { ...payload, phone },
      vertical: props.vertical
    }
    await $fetch('/api/braze/track-user', {
      method: 'POST',
      canCancel: true,
      body: params
    })
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('braze track error: ', err)
  }
}

const sendCallbackRequest = async payload => {
  // Lead submission for energy, life & home loans vertical
  if (props.vertical && newRequestCallbackVerticals.includes(props.vertical)) {
    const utms = utmsStore.utms ?? {}
    payload = {
      ...utms,
      ...props.additionalParams,
      ...payload,
      name: payload.fullname
    }
    // Specific to energy vertical
    if (props.vertical === VERTICAL.ENERGY) {
      payload.account_type = 'Residential'
      payload.partner_rule = 'cc_eng_internal'
    }
    delete payload.id
    delete payload.uuid

    const { data } = await $fetch('/api/lead/create', {
      method: 'POST',
      canCancel: true,
      body: payload
    })

    if (data?.error) {
      throw data.error
    }

    if (brazeTrackVerticals.includes(props.vertical)) {
      await brazeCallbackTrack({
        ...payload
      })
    }

    return data ?? undefined
  }

  // Email sending for other verticals
  const { data } = await $fetch('/api/callback/mail', {
    method: 'POST',
    canCancel: true,
    body: payload
  })

  if (data?.error) {
    throw data.error
  }

  return data?.status ?? undefined
}

const handleSubmitForm = async () => {
  await handleValidateForm()
  const hasError = await handleScrollToError(toRaw(validation.value))
  if (hasError) {
    return hasError
  }

  submitInProgress.value = true
  try {
    // Submit the data to the leads API
    const responseStatus = await sendCallbackRequest(callbackParams.value)
    if (responseStatus === 'mail_sent') {
      $toast.success('Thank you. We will be in touch shortly.')
      formSubmitted.value = true
      $tracking.gsdCallback({
        name: callbackParams.value.fullname,
        phoneNumber: callbackParams.value.phone,
        vertical: props.vertical
      })
    } else if (responseStatus?.lead_uuid) {
      $toast.success('Thank you. We will be in touch shortly.')
      formSubmitted.value = true
    } else {
      $toast.error('An error occurred while sending your message.')
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log({ error })
    $toast.error('An error occurred while sending your message.')
  }
  submitInProgress.value = false
}

const emit = defineEmits(['close', 'submit-click'])

function clearForm () {
  formModel.value = {}
  validation.value = {}
  handleModels()
  submitInProgress.value = false
  formSubmitted.value = false
}

function closeEvent () {
  if (formSubmitted.value) {
    clearForm()
  }

  emit('close')
}

function cancel () {
  // submitInProgress.value = false
  // formSubmitted.value = false
  clearForm()
  emit('close')
}
</script>
